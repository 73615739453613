import IAuthService from "../interfaces/IAuthService";
import httpClient from "@/utils/httpClient";
import authClient from "@/utils/authClient";
import cookie from "vue-cookie";

export default class ApiAuthService extends IAuthService {
    async login(userId, password) {
        try {
            const authResponse = await authClient.post(
                "login",
                {
                    username: userId,
                    password: password,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods":
                            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Origin, Content-Type, X-Auth-Token",
                    },
                }
            );

            if (authResponse.status === 200) {
                // Simpan token
                const token = authResponse.data.token;
                const refreshToken = authResponse.data.refreshToken;
                cookie.set("token", token);
                cookie.set("refreshToken", refreshToken);

                // Ambil data profile menggunakan token
                const profileResponse = await this.getProfile(token);

                if (profileResponse.success) {
                    // Simpan data user dan profile_url
                    cookie.set("user", JSON.stringify(profileResponse.data));
                    if (profileResponse.data.profile_url) {
                        cookie.set(
                            "profile_url",
                            profileResponse.data.profile_url
                        );
                    }

                    return {
                        success: true,
                        data: {
                            ...authResponse.data,
                            user: profileResponse.data,
                        },
                    };
                }

                if (!profileResponse.success) {
                    cookie.delete("token");
                    cookie.delete("user");
                    cookie.delete("refreshToken");
                    cookie.delete("profile_url");
                    return {
                        success: false,
                        error: "Failed to get user profile",
                    };
                }
            }

            cookie.delete("token");
            cookie.delete("user");
            cookie.delete("refreshToken");
            cookie.delete("profile_url");
            return { success: false, error: "Invalid credentials" };
        } catch (error) {
            cookie.delete("token");
            cookie.delete("user");
            cookie.delete("refreshToken");
            cookie.delete("profile_url");
            return { success: false, error: error.message };
        }
    }

    async getProfile(token) {
        try {
            const response = await authClient.get("/me", {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                        "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Origin, Content-Type, X-Auth-Token, Authorization",
                },
            });
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getRole() {
        try {
            // Ambil user data dari cookie
            const userData = JSON.parse(cookie.get("user"));
            if (userData) {
                // Tentukan role berdasarkan administrator flag
                const role = userData ? ["Administrator"] : ["Personel"];

                return {
                    success: true,
                    data: {
                        rows: role,
                    },
                };
            }

            return { success: false, error: "User data not found" };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getMenu() {
        try {
            const response = await httpClient.get("/menu");
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getApps() {
        try {
            const response = await httpClient.get("/apps");
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async getPrivilege() {
        try {
            const response = await httpClient.get("/privilege");
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async register(params) {
        try {
            const response = await httpClient.post("/register", params);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async registerVerificationCode(params) {
        try {
            const response = await httpClient.post(
                "/register_validate_code",
                params
            );
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async forgotPassword(email) {
        try {
            const response = await authClient.get(
                `/requestresetpassword?email=${email}`
            );
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async forgotPasswordVerificationCode(email, code) {
        try {
            const response = await authClient.post(
                "/resetpasswordverification",
                {
                    email: email,
                    code: code,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods":
                            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Origin, Content-Type, X-Auth-Token",
                    },
                }
            );
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async resetPassword(params) {
        try {
            const response = await httpClient.post("/reset_password", params);
            return { success: true, data: response.data };
        } catch (error) {
            return { success: false, error: error.message };
        }
    }

    async changePassword(oldPassword, newPassword) {
        try {
            const response = await authClient.post("changepassword", {
                oldPassword: oldPassword,
                newPassword: newPassword,
            });

            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            console.error("Change password error:", error);
            return {
                success: false,
                error: error.message,
                status: error.response?.status,
            };
        }
    }

    async refreshToken(refreshToken) {
        try {
            const response = await authClient.post("refreshtoken", {
                refreshToken: refreshToken,
            });

            return {
                success: true,
                data: response.data,
            };
        } catch (error) {
            console.error("Refresh token error:", error);
            return {
                success: false,
                error: error.message,
            };
        }
    }

    // Implementasi method lainnya...
}
