import {
    collection,
    query,
    where,
    orderBy,
    limit,
    onSnapshot,
    doc,
    setDoc,
    addDoc,
    serverTimestamp,
    getDoc,
    updateDoc,
    getDocs,
    writeBatch,
    startAfter,
} from "firebase/firestore";
import { db } from "@/config/firebase";

// Constants untuk query limits
const QUERY_LIMITS = {
    CHATS: 10,
    MESSAGES: 10,
};

export const firebaseChat = {
    // Menyimpan subscription untuk cleanup
    unsubscribeChats: null,
    unsubscribeUserStatus: {},
    statusUpdateTimeout: null,
    unsubscribeMessages: {},

    /**
     * Melakukan subscribe ke chat-chat pengguna dan memantau perubahannya secara real-time
     * @param {string} userId - ID dari pengguna yang ingin dipantau chatnya
     * @param {Function} callback - Fungsi callback yang akan dipanggil ketika ada perubahan data
     * @returns {Function} Fungsi untuk unsubscribe dari listener
     */
    async subscribeToUserChats(
        userId,
        callback,
        lastChat = null,
        limitCount = QUERY_LIMITS.CHATS
    ) {
        try {
            let q;
            const chatsRef = collection(db, "chats");

            if (lastChat) {
                q = query(
                    chatsRef,
                    where("participants", "array-contains", userId.toString()),
                    orderBy("updatedAt", "desc"),
                    startAfter(lastChat.updatedAt),
                    limit(limitCount)
                );
            } else {
                q = query(
                    chatsRef,
                    where("participants", "array-contains", userId.toString()),
                    orderBy("updatedAt", "desc"),
                    limit(limitCount)
                );
            }

            return onSnapshot(q, {
                next: async (snapshot) => {
                    const chats = snapshot.docs.map((doc) => {
                        const data = doc.data();
                        return {
                            id: doc.id,
                            ...data,
                            lastMessage: data.lastMessage
                                ? {
                                      ...data.lastMessage,
                                      timestamp:
                                          data.lastMessage.timestamp || null,
                                  }
                                : null,
                            updatedAt: data.updatedAt || null,
                        };
                    });

                    callback({
                        success: true,
                        data: chats,
                        hasMore: chats.length >= limitCount,
                    });
                },
                error: (error) => {
                    console.error(
                        "FirebaseChatService: Subscription error:",
                        error
                    );
                    callback({
                        success: false,
                        error,
                        hasMore: false,
                    });
                },
            });
        } catch (error) {
            console.error(
                "FirebaseChatService: Error in subscribeToUserChats:",
                error
            );
            throw error;
        }
    },

    async subscribeToUserStatus(userId, callback) {
        try {
            // Pastikan userId dalam bentuk string
            const userIdString = userId?.toString() || "";

            if (!userIdString) {
                console.error("❌ [Firebase] Invalid userId:", userId);
                callback({ success: false, error: "Invalid userId" });
                return;
            }

            // Cleanup existing subscription
            if (this.unsubscribeUserStatus[userIdString]) {
                this.unsubscribeUserStatus[userIdString]();
            }

            const userStatusRef = doc(db, "userStatus", userIdString);

            this.unsubscribeUserStatus[userIdString] = onSnapshot(
                userStatusRef,
                {
                    next: (doc) => {
                        const status = doc.exists()
                            ? doc.data().status
                            : "offline";
                        callback({ success: true, data: status });
                    },
                    error: (error) => {
                        console.error(
                            `❌ [Firebase] Status subscription error for ${userIdString}:`,
                            error
                        );
                        callback({ success: false, error });
                    },
                }
            );
            return this.unsubscribeUserStatus[userIdString];
        } catch (error) {
            console.error(
                `❌ [Firebase] Error in subscribeToUserStatus for ${userId}:`,
                error
            );
            throw error;
        }
    },

    /**
     * Membersihkan semua Firebase subscriptions
     */
    cleanup() {
        // Cleanup chat subscription
        if (this.unsubscribeChats) {
            this.unsubscribeChats();
            this.unsubscribeChats = null;
        }

        // Cleanup status subscriptions
        Object.entries(this.unsubscribeUserStatus).forEach(([unsubscribe]) => {
            if (typeof unsubscribe === "function") {
                unsubscribe();
            }
        });
        this.unsubscribeUserStatus = {};

        // Cleanup status update timeout
        if (this.statusUpdateTimeout) {
            clearTimeout(this.statusUpdateTimeout);
            this.statusUpdateTimeout = null;
        }

        // Cleanup message subscriptions
        Object.entries(this.unsubscribeMessages).forEach(([unsubscribe]) => {
            if (typeof unsubscribe === "function") {
                unsubscribe();
            }
        });
        this.unsubscribeMessages = {};
    },

    /**
     * Update status user
     * @param {string} userId - ID user yang akan diupdate statusnya
     * @param {string} status - Status baru ('online' atau 'offline')
     */
    async updateUserStatus(userId, status) {
        try {
            // Pastikan userId dalam bentuk string
            const userIdString = userId?.toString() || "";
            if (!userIdString) {
                throw new Error("Invalid userId");
            }

            const userStatusRef = doc(db, "userStatus", userIdString);

            try {
                // Coba update dokumen yang sudah ada
                await updateDoc(userStatusRef, {
                    status,
                    lastUpdated: serverTimestamp(),
                });
            } catch (error) {
                // Jika error karena dokumen tidak ada, buat dokumen baru
                if (error.code === "not-found") {
                    await setDoc(userStatusRef, {
                        status: "online",
                        lastUpdated: serverTimestamp(),
                        userId: userIdString, // tambahkan userId ke dokumen
                    });
                } else {
                    throw error; // lempar error lain jika bukan 'not-found'
                }
            }

            return { success: true };
        } catch (error) {
            console.error("❌ Error updating user status:", error);
            return { success: false, error };
        }
    },

    async subscribeToMessages(chatId, callback) {
        try {
            // Cleanup existing subscription
            if (this.unsubscribeMessages[chatId]) {
                this.unsubscribeMessages[chatId]();
            }

            const messagesRef = collection(db, "chats", chatId, "messages");
            const q = query(
                messagesRef,
                orderBy("timestamp", "desc"),
                limit(QUERY_LIMITS.MESSAGES)
            );

            this.unsubscribeMessages[chatId] = onSnapshot(q, {
                next: async (snapshot) => {
                    const messages = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));

                    // Check if there are more messages
                    let hasMore = false;
                    if (messages.length > 0) {
                        const lastMessage = messages[messages.length - 1];
                        const nextQuery = query(
                            messagesRef,
                            orderBy("timestamp", "desc"),
                            where("timestamp", "<", lastMessage.timestamp),
                            limit(1)
                        );
                        const nextSnapshot = await getDocs(nextQuery);
                        hasMore = !nextSnapshot.empty;
                    }

                    callback({
                        success: true,
                        data: messages,
                        hasMore,
                    });
                },
                error: (error) => {
                    console.error(
                        `❌ [Firebase] Messages subscription error for ${chatId}:`,
                        error
                    );
                    callback({ success: false, error, hasMore: false });
                },
            });

            return this.unsubscribeMessages[chatId];
        } catch (error) {
            console.error(
                `❌ [Firebase] Error in subscribeToMessages for ${chatId}:`,
                error
            );
            throw error;
        }
    },

    async sendMessage(chatId, messageData) {
        try {
            // Pisahkan data untuk pesan dan unread counts
            const { getCurrentUserId, participants, ...messageOnly } =
                messageData;

            // 1. Simpan pesan
            const messagesRef = collection(db, "chats", chatId, "messages");
            const message = {
                ...messageOnly,
                timestamp: serverTimestamp(),
                readBy: [getCurrentUserId],
            };
            const docRef = await addDoc(messagesRef, message);

            // 2. Update unreadCounts dan lastMessage dengan delay kecil
            const chatRef = doc(db, "chats", chatId);
            const chatDoc = await getDoc(chatRef);

            if (chatDoc.exists() && Array.isArray(participants)) {
                const chatData = chatDoc.data();
                const currentUnreadCounts = chatData.unreadCounts || {};

                // Tambahkan delay kecil sebelum update
                await new Promise((resolve) => setTimeout(resolve, 100));

                // Update unreadCounts untuk setiap participant
                const updatedUnreadCounts = participants.reduce(
                    (acc, participantId) => {
                        if (participantId === getCurrentUserId) {
                            acc[participantId] = 0;
                        } else {
                            acc[participantId] =
                                (currentUnreadCounts[participantId] || 0) + 1;
                        }
                        return acc;
                    },
                    {}
                );

                // Tentukan content berdasarkan tipe pesan
                let messageContent = messageOnly.content;
                if (!messageContent && messageOnly.fileName) {
                    if (messageOnly.type === "image") {
                        messageContent = "Photo";
                    } else if (messageOnly.type === "file") {
                        messageContent = `File: ${messageOnly.fileName}`;
                    }
                }

                // Siapkan data lastMessage
                const lastMessage = {
                    content: messageContent,
                    readBy: [getCurrentUserId],
                    senderId: getCurrentUserId,
                    timestamp: serverTimestamp(),
                    type: messageOnly.type || "text",
                };

                // Update chat document
                await updateDoc(chatRef, {
                    unreadCounts: updatedUnreadCounts,
                    lastMessage,
                    updatedAt: serverTimestamp(),
                });
            }

            return { success: true, messageId: docRef.id };
        } catch (error) {
            console.error("❌ [Firebase] Error sending message:", error);
            throw error;
        }
    },

    async updateLastMessageReadBy(chatId, readBy) {
        try {
            const chatRef = doc(db, "chats", chatId);
            await updateDoc(chatRef, {
                "lastMessage.readBy": readBy,
            });
            return { success: true };
        } catch (error) {
            console.error("Error updating lastMessage readBy:", error);
            return { success: false, error };
        }
    },

    async updateUnreadCounts(chatId, unreadCounts) {
        try {
            const chatRef = doc(db, "chats", chatId);

            // Check if document exists first
            const chatDoc = await getDoc(chatRef);
            if (!chatDoc.exists()) {
                return { success: false, error: "Chat document not found" };
            }

            await updateDoc(chatRef, { unreadCounts });
            return { success: true };
        } catch (error) {
            console.error(
                "FirebaseChatService: Error updating unreadCounts:",
                error
            );
            return { success: false, error };
        }
    },

    async updateMessageReadBy(chatId, readBy) {
        try {
            // Get messages collection reference
            const messagesRef = collection(db, "chats", chatId, "messages");

            // Query messages yang belum memiliki readBy atau belum dibaca oleh user
            const q = query(messagesRef, where("readBy", "not-in", [readBy]));

            const snapshot = await getDocs(q);

            // Batch update untuk semua messages
            const batch = writeBatch(db);

            snapshot.docs.forEach((doc) => {
                const messageRef = doc.ref;
                const currentReadBy = doc.data().readBy || [];
                if (!currentReadBy.includes(readBy)) {
                    batch.update(messageRef, {
                        readBy: [...currentReadBy, readBy],
                    });
                }
            });

            // Execute batch update
            await batch.commit();
            return { success: true };
        } catch (error) {
            console.error(
                "❌ [Firebase] Error updating messages readBy:",
                error
            );
            return { success: false, error };
        }
    },

    async createChat({ participants, createdBy, type, unreadCounts }) {
        try {
            // Check if chat already exists between these participants
            const chatsRef = collection(db, "chats");
            const q = query(
                chatsRef,
                where("participants", "array-contains", participants[0]),
                where("type", "==", "private")
            );

            const querySnapshot = await getDocs(q);

            // Find existing private chat between these participants
            const existingChat = querySnapshot.docs.find((doc) => {
                const chatData = doc.data();
                return chatData.participants.includes(participants[1]);
            });

            if (existingChat) {
                return {
                    id: existingChat.id,
                    ...existingChat.data(),
                    messages: [],
                };
            }

            // If no existing chat, create new one
            const chatData = {
                participants,
                createdBy,
                type,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                lastMessage: null,
                unreadCounts,
            };

            const newChatRef = await addDoc(chatsRef, chatData);
            const chatDoc = await getDoc(newChatRef);

            if (chatDoc.exists()) {
                return {
                    id: chatDoc.id,
                    ...chatDoc.data(),
                    messages: [],
                };
            }

            throw new Error("Failed to create chat");
        } catch (error) {
            console.error("❌ Error creating chat:", error);
            throw error;
        }
    },

    async deleteChat(chatId) {
        try {
            const chatRef = doc(db, "chats", chatId);
            const chatDoc = await getDoc(chatRef);

            if (!chatDoc.exists()) {
                throw new Error("Chat not found");
            }

            // Get messages subcollection
            const messagesRef = collection(chatRef, "messages");
            const messagesSnapshot = await getDocs(messagesRef);

            // Batch delete
            const batch = writeBatch(db);
            messagesSnapshot.docs.forEach((messageDoc) => {
                batch.delete(messageDoc.ref);
            });
            batch.delete(chatRef);

            await batch.commit();

            return { success: true };
        } catch (error) {
            console.error("FirebaseChatService: Error in deleteChat:", error);
            throw error;
        }
    },

    async loadMoreMessages(chatId, lastMessageTimestamp) {
        try {
            const messagesRef = collection(db, "chats", chatId, "messages");
            const q = query(
                messagesRef,
                orderBy("timestamp", "desc"),
                where("timestamp", "<", lastMessageTimestamp),
                limit(QUERY_LIMITS.MESSAGES)
            );

            const snapshot = await getDocs(q);
            const messages = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            return {
                success: true,
                data: messages,
                hasMore: messages.length >= QUERY_LIMITS.MESSAGES,
            };
        } catch (error) {
            console.error("❌ [Firebase] Error loading more messages:", error);
            return { success: false, error };
        }
    },

    async createGroupChat(groupData) {
        try {
            const chatsRef = collection(db, "chats");

            const chatData = {
                ...groupData,
                type: "group",
                updatedAt: serverTimestamp(),
                unreadCounts: groupData.unreadCounts,
            };

            const newChatRef = await addDoc(chatsRef, chatData);
            const chatDoc = await getDoc(newChatRef);

            if (chatDoc.exists()) {
                return {
                    id: chatDoc.id,
                    ...chatDoc.data(),
                    messages: [],
                };
            }

            throw new Error("Failed to create group chat");
        } catch (error) {
            console.error("❌ Error creating group chat:", error);
            throw error;
        }
    },
};
